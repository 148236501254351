import api from "services/api";
import { backendurl, apiLocalURL, apiURL } from "utils/urls";

import axios from "axios";

export const getServerData = async (url, token) => {
  try {
    const res = await api.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    return {
      "Erro de conexão com o painel": error.message,
      status: error.response.status,
    };
  }
};

export const getServerDataNew = async (url, token) => {
  try {
    const res = await axios.get(apiLocalURL + url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    return {
      "Erro de conexão com o painel": error.message,
      status: error.response.status,
    };
  }
};

export const getServerDataNoAuth = async (url) => {
  try {
    const res = await axios.get(apiLocalURL + url);
    return res.data;
  } catch (error) {
    return {
      "Erro de conexão com o painel": error.message,
      status: error.response.status,
    };
  }
};

export const postServerData = async (url, options, token) => {
  try {
    const res = await api.post(url, options, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    return {
      "Erro de conexão com o painel": error.message,
      status: error.response.status,
    };
  }
};

export const putServerData = async (url, options, token) => {
  try {
    const res = await api.put(url, options, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    return {
      "Erro de conexão com o painel": error.message,
      status: error.response.status,
    };
  }
};

export const delServerData = async (url, token) => {
  try {
    const res = await api.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    return {
      "Erro de conexão com o painel": error.message,
      status: error.response.status,
    };
  }
};

export const runBuild = async () => {
  try {
    const res = await axios.get(`${apiURL}/runbuilds/run`);
    return res.status;
  } catch (error) {
    throw error;
  }
};
